import { getCityCountryName } from 'Utils/cityUtils';
import { getWebPathString } from 'Utils/urlUtils';

import { SEARCH_DEBOUNCE_DELAY, SEARCH_ENTITY_TYPE } from 'Constants/constants';

export const doesCityMatchesQuery = ({
	query,
	cityResult,
}: {
	query: string;
	cityResult: { displayName: string };
}) => {
	if (!cityResult) return false;

	const trimmedQuery = query.trim().toLowerCase();
	return (
		trimmedQuery.length > 2 &&
		cityResult.displayName.toLowerCase().startsWith(trimmedQuery)
	);
};

export const searchInputFocusHandler = ({
	searchInput,
	timeout,
}: {
	searchInput: HTMLElement;
	timeout: number;
}) => {
	// Fix for ios device - faking a input focus
	const input = document.createElement('input');
	input.style.position = 'fixed';
	input.style.top = '0';
	input.style.opacity = '0';
	input.autocomplete = 'off';

	window.document.body.appendChild(input);
	input.focus();

	setTimeout(() => {
		searchInput?.focus();
		window.document.body.removeChild(input);
	}, timeout);
};

const labelResults = ({ arr, key = 'searchType', val }: any) => {
	return arr.map((item: any) => {
		item[key] = val;
		return item;
	});
};

const getCityCountryDescription = ({ cityName, countryName }: any) =>
	`${cityName}${countryName ? `, ${countryName}` : ''}`;

export const getCombinedSearchResults = ({
	topCitySearchCodeResults,
	topProductSearchCodeResults,
	topCollectionsSearchCodeResults,
	universalInputValue,
	citiesMap,
	lang,
	isNativeSearch = false,
}: {
	topCitySearchCodeResults: Array<Object>;
	topProductSearchCodeResults: Array<Object>;
	topCollectionsSearchCodeResults: Array<Object>;
	universalInputValue: string;
	citiesMap: any;
	lang: string;
	isNativeSearch?: boolean;
}) => {
	const collectionResults = labelResults({
		arr: topCollectionsSearchCodeResults,
		val: SEARCH_ENTITY_TYPE.COLLECTION,
	});
	const productResults = labelResults({
		arr: topProductSearchCodeResults,
		val: SEARCH_ENTITY_TYPE.PRODUCT,
	});
	const cityResults = labelResults({
		arr: topCitySearchCodeResults,
		val: SEARCH_ENTITY_TYPE.CITY,
	});

	const doesCityQueryMatches = doesCityMatchesQuery({
		query: universalInputValue,
		cityResult: topCitySearchCodeResults?.[0] as { displayName: string },
	});

	const combinedResults = doesCityQueryMatches
		? [...cityResults, ...productResults, ...collectionResults]
		: [...productResults, ...collectionResults, ...cityResults];

	combinedResults.forEach(results => {
		let pageHref, countryAndCityDescription;
		const {
			cityCode,
			urlSlugs,
			id,
			cityName,
			searchType,
			urlSlug,
			city,
			code: nativeCityCode,
		} = results;

		const nativeSearchCityCode = city?.code ?? nativeCityCode;

		const countryName = getCityCountryName(
			citiesMap,
			isNativeSearch ? nativeSearchCityCode : cityCode,
		);

		switch (searchType) {
			case SEARCH_ENTITY_TYPE.CITY:
				pageHref = isNativeSearch
					? urlSlug
					: urlSlugs[lang?.toUpperCase() || 'EN'];
				countryAndCityDescription = countryName;
				break;
			case SEARCH_ENTITY_TYPE.COLLECTION:
				pageHref = isNativeSearch
					? urlSlug
					: `${lang ? `/${lang}` : ''}/collection/${id}`;
				countryAndCityDescription = getCityCountryDescription({
					cityName: isNativeSearch ? city.displayName : cityName,
					countryName,
				});
				break;
			case SEARCH_ENTITY_TYPE.PRODUCT:
				pageHref = isNativeSearch
					? urlSlug
					: `${lang ? `/${lang}` : ''}/tour/${id}`;
				countryAndCityDescription = getCityCountryDescription({
					cityName: isNativeSearch ? city.displayName : cityName,
					countryName,
				});
				break;
			default:
				pageHref = null;
				break;
		}
		results.countryAndCityDescription = countryAndCityDescription;
		results.pageHref = pageHref;
	});

	return combinedResults;
};

export const getSearchPageUrl = ({
	lang,
	query,
	currentCity,
}: {
	lang: string;
	query: string;
	currentCity?: { displayName: string; name: string };
}) => {
	return `${lang ? `/${lang}` : ''}/search?q=${query}&c=${getWebPathString(
		currentCity?.name || '',
	)}`;
};

export const getSuggestionsLoadTime = (startTime: number) =>
	Date.now() - startTime + SEARCH_DEBOUNCE_DELAY;
